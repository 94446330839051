<template>
  <b-card class="blog-edit-wrapper">
    <!-- form -->
    <div class="text-center">
      <h4 class="mt-5">
        Course Content
        <feather-icon icon="Edit2Icon" />
      </h4>
    </div>
    <validation-observer ref="addCourseFormvalidate">
      <b-form
        ref="form"
        class="mt-2"
        style="width: 100%"
        @submit.prevent="save"
      >
        <b-row>
          <b-col md="6">
            <b-form-group
              label="Category"
              label-for="blog-edit-category"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Category"
                rules=""
              >
                <v-select
                  id="blog-edit-category"
                  v-model="addCourseForm.category_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="type"
                  :options="categoriesList"
                  :reduce="(val) => val.id"
                  @input="getCoursesByCategory(addCourseForm.category_id)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="Course"
              label-for="blog-edit-category"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Course"
                rules=""
              >

                <v-select
                  id="blog-edit-category"
                  v-model="addCourseForm.course_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="coursesList"
                  :reduce="(val) => val.id"

@input="getDuration(addCourseForm.course_id)"
                >
                  <template
                    v-if="!getCourse"
                    #no-options="{ search, searching, loading }"
                  >
                    Select Category First ...
                  </template>
                  <template
                    v-else
                    #no-options="{ search, searching, loading }"
                  >
                    Loading ...
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Related Courses"
              label-for="blog-edit-course"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Courses"
                rules=""
              >
                <v-select
                  id="blog-edit-category"
                  v-model="addCourseForm.related_courses"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  multiple
                  :options="RelatedCourse"

                  :reduce="(val) => val.id"
                >
                  <template #no-options="{ search, searching, loading }">
                    No data ...
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            class=""
          >
            <b-form-group
              label="Description"
              label-for="blog-content"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Description"
                rules="required"
              >
              <QuillEditor
                  :editorRef="'onlinewhatWeDo4Editor'"
                v-model="addCourseForm.description"
                />
                 
               
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Objectives"
              label-for="blog-content"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="objectives"
                rules="required"
              >
               <QuillEditor
                  :editorRef="'objectiveswhatWeDo4Editor'"
              v-model="addCourseForm.objectives"
                />
                 
               
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group
              label="Who should attend?"
              label-for="blog-content"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Description"
                rules="required"
              >
               <QuillEditor
                  :editorRef="'attenderswhatWeDo4Editor'"
             v-model="addCourseForm.attenders"
                />
                 
              
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Select Day"
              label-for="blog-edit-course"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="day"
                rules=""
              >
                <v-select
                  id="blog-s-category"
                  v-model="addCourseForm.day"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="durationList"
                  @input="getDayContent(addCourseForm.day)"
                  />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Day Content
"
              label-for="blog-content"
              class="mb-2"
            >

              <validation-provider
                #default="{ errors }"
                name="Day Content"
                rules="required"
              >
                <QuillEditor
                  :editorRef="'adayContentFormwhatWeDo4Editor'"
             v-model="dayContentForm"
                />
               

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            class="mt-50"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save"
            >
              Save Changes
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,

  BForm,
  BRow,
  BCol,
  BFormGroup,

  BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'

import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref, reactive, watch } from '@vue/composition-api'
import Editor from '@tinymce/tinymce-vue'
import Vue from 'vue'
import store from '@/store'
import QuillEditor from '@core/components/editor/Editor'
export default {
  setup() {
    // const store = useStore();
    const refInputEl = ref(null)
    const refInputE2 = ref(null)
    const refPreviewEl = ref(null)
    const refPreviewE2 = ref(null)
    const getCourse = ref(false)
    const durationList = ref([])
    const Tabimage = ref('')
    const coursesList = ref([])
    const RelatedCourse = ref([])
    const dayContent = ref([])
    const categoriesList = ref([])
    // store.dispatch('courses/getAllCourses')
    //     .then(response => {
    //      //   console.log("all",response)
    //       RelatedCourse.value = response.data.data;

    //     })
    const addCourseForm = reactive({
      category_id: '',
      course_id: '',
      description: '',
      objectives: '',
      attenders: '',
      related_courses: '',
      days_content: '',
      day: 1,
    })
    const getCoursesByCategory = id => {
      getCourse.value = true
      addCourseForm.course_id = ''
      coursesList.value = []
      RelatedCourse.value = []
      store
        .dispatch('categories/getCoursesByCategory', {
          category_id: id,
        })
        .then(response => {
          response.data.data.forEach(el => {
            if (el.online == 1) {
              coursesList.value.push({
                id: el.id,
                name: `${el.name} Online`,
                link_id: el.link_id,
                related_courses: el.related_courses,
                attenders: el.attenders,
                objectives: el.objectives,
                days_content: el.days_content,
                description: el.description,
                duration: el.duration,

              })
            } else {
              coursesList.value.push({
                id: el.id,
                name: el.name,
                link_id: el.link_id,
                related_courses: el.related_courses,
                attenders: el.attenders,
                objectives: el.objectives,
                days_content: el.days_content,
                description: el.description,
                duration: el.duration,
              })
            }
          })
          RelatedCourse.value = coursesList.value
        })
    }
    const form = ref()
    const daycontentArray = ref([])
    const dayContentForm = ref('')
    watch(dayContentForm, (val, oldVal) => {
      if (!dayContentForm.value) {
        dayContentForm.value = ''
      }
      if (dayContentForm.value !== '') {
        daycontentArray.value[addCourseForm.day - 1] = dayContentForm.value
      } else if (daycontentArray.value[addCourseForm.day - 1]) {
        dayContentForm.value = daycontentArray.value[addCourseForm.day - 1]
      }
    })
    const getDayContent = num => {
      if (dayContent.value) {
        if (dayContent.value[num - 1] !== null) {
          dayContentForm.value = daycontentArray.value[num - 1]
        } else {
          dayContentForm.value = ''
        }
      } else {
        dayContentForm.value = ''
      }
    }
    const getDuration = id => {
      store.dispatch('courses/GetCourse', { id }).then(response => {
        let item = response?.data
        if (item) {
          durationList.value = []
          addCourseForm.day = 1
          for (let i = 1; i <= item.duration; i++) {
            durationList.value.push(i)
          }
          console.log(item.days_content)
          addCourseForm.related_courses = JSON.parse(item.related_courses)
          addCourseForm.attenders = item.attenders
          addCourseForm.objectives = item.objectives

          dayContent.value = JSON.parse(item.days_content)
          dayContentForm.value = dayContent.value[0]
          console.log(dayContentForm.value)
          addCourseForm.description = item.description
          daycontentArray.value = JSON.parse(item.days_content)
        } else {
          addCourseForm.related_courses = ''
          addCourseForm.attenders = ''
          addCourseForm.description = ''
          addCourseForm.objectives = ''
          dayContentForm.value = ''
          dayContent.value = []
        }
      })
    }
   
    store.dispatch('categories/AllCategory').then(response => {
      categoriesList.value = response.data.data
    })
    const editorConfig = ref({
      toolbar: [
        { name: 'save', items: ['savebtn', 'Undo', 'Redo'] },
        { name: 'clipboard', items: ['Cut', 'Copy', 'image|', 'image|Text', 'image|FromWord'] },
        { name: 'document', items: ['Find', 'Replace'] },
        '/',
        { name: 'lists', items: ['NumberedList', 'BulletedList', 'Outdent', 'Indent'] },
        { name: 'insert', items: ['Image', 'Table', 'Smiley', 'SpecialChar'] },
        { name: 'link', items: ['Link', 'Unlink'] },
        '/',
        { name: 'basicstyles', items: ['Font', 'FontSize', 'Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript'] },
        // '/',
        { name: 'align', items: ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'] },
      ],

    })
    const getContent = val => {

    }
    store.dispatch('categories/AllCategory').then(response => {
      categoriesList.value = response.data.data
    })

    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      base64 => {
        refPreviewEl.value.src = base64
      },
    )
    const { inputImageRendererTab } = useInputImageRenderer(
      refInputE2,
      base64 => {
        refPreviewE2.value.src = base64
      },
    )
    const addCourseFormvalidate = ref()
    const simple = ref()

    const save = () => {
      const formData = new FormData()

      formData.append('category_id', addCourseForm.category_id)
      formData.append('course_id', addCourseForm.course_id)
      formData.append('description', addCourseForm.description)
      formData.append('objectives', addCourseForm.objectives)
      formData.append('attenders', addCourseForm.attenders)
      if (addCourseForm.related_courses == null) {
        formData.append(
          'related_courses',
          JSON.stringify([]),
        )
      } else {
        formData.append(
          'related_courses',
          JSON.stringify(addCourseForm.related_courses),
        )
      }
      formData.append(' days_content', JSON.stringify(daycontentArray.value))

      store
        .dispatch('content/addCourseContent', formData)
        .then(response => {
          daycontentArray.value = []
          dayContentForm.value = ''
          durationList.value = []
          addCourseForm.day = 1
          addCourseForm.related_courses = []
          form.value.reset()
          Vue.swal({
            title: 'Course Content Added ',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })

          addCourseForm.category_id = ''
          addCourseForm.course_id = ''
          addCourseForm.description = ''
          addCourseForm.objectives = ''
          addCourseForm.attenders = ''
          dayContentForm.value = ''
          dayContent.value = []
        })
        .catch(error => {
          Vue.swal({
            title: '',
            text: `${error.response.data.message}`,
            icon: 'error',
            confirmButtonText: 'Ok',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
    }
    function example_image_upload_handler(blobInfo, success, failure, progress) {
      let xhr; let
        formData
      const token = localStorage.getItem('token')

      xhr = new XMLHttpRequest()
      xhr.withCredentials = false
      xhr.open('POST', 'https://api.lmitac.com/api/upload_image_tiny_mce')

      xhr.upload.onprogress = function (e) {
        progress(e.loaded / e.total * 100)
      }
      xhr.setRequestHeader('Authorization', `Bearer ${token}`)
      xhr.onload = function () {
        let json

        if (xhr.status === 403) {
          failure(`HTTP Error: ${xhr.status}`, { remove: true })
          return
        }

        if (xhr.status < 200 || xhr.status >= 300) {
          failure(`HTTP Error: ${xhr.status}`)
          return
        }

        json = JSON.parse(xhr.responseText)

        if (!json || typeof json.location !== 'string') {
          failure(`Invalid JSON: ${xhr.responseText}`)
          return
        }

        success(json.location)
      }

      xhr.onerror = function () {
        failure(`Image upload failed due to a XHR Transport error. Code: ${xhr.status}`)
      }

      formData = new FormData()
      formData.append('image', blobInfo.blob(), blobInfo.filename())

      xhr.send(formData)
    }

    return {
      example_image_upload_handler,
      refInputEl,
      refPreviewEl,
      refInputE2,
      refPreviewE2,
      inputImageRendererTab,
      Tabimage,
      addCourseForm,
      inputImageRenderer,
      addCourseFormvalidate,
      daycontentArray,
      coursesList,
      categoriesList,
      getDuration,
      durationList,
      simple,
      getDayContent,
      getCoursesByCategory,
      getCourse,
      RelatedCourse,
      save,
      required,
      email,
      dayContent,
      getContent,
      dayContentForm,
      editorConfig,
      form,

    }
  },

  components: {
    BCard,
  QuillEditor,
    Editor,
    ValidationProvider,
    ValidationObserver,

    BForm,

    BRow,
    BCol,
    BButton,
    BFormGroup,

    vSelect,

  },

  directives: {
    Ripple,
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>
<style>
.quill-editor {
  margin-left: 1px;
  width: 100%;
}
</style>
<style>
.mb-2.col-12 {
  margin: -1px !important;
}
.border.rounded.p-2 {
  margin: 8px 1px !important;
}
.col-12 {
  margin: 0px;
}
</style>
